module.exports = {
  "siteTitle": "Tintodo - Qué hacer hoy",
  "shortSiteTitle": "Tintodo  - Qué hacer hoy",
  "siteDescription": "Descubre que hacer hoy. Todos los eventos, eventos online, películas y series de plataformas de streaming en una app.",
  "heroHeaderInit": "Tintodo - Qué hacer hoy",
  "heroSubheaderInit": "Descubre que hacer hoy. Todos los eventos, eventos online, películas y series de plataformas de streaming en una app.",
  "headerTitle": "Tintodo",
  "headerSubtitle": "Qué hacer hoy",
  "home": "Inicio",
  "posts": "Posts",
  "categories": "Categorías",
  "about": "Acerca de Tintodo",
  "privacyPolicy": "Política de privacidad",
  "contact": "Contacto",
  "selectLanguage": "Idioma:",
  "postsCategory": "Posts en la categoría",
  "potsCount1": `Hay 1 post en la categoría`,
  "potsCountPart1": `Hay `,
  "potsCountPart2": ` posts en la categoría`,
  "contactPart1": "Puedes contactar con nosotros enviando un email a ",
  "contactPart2": " o en otras plataformas como: ",
  "contactPart3": " o ",
  "authorExplanation": " La app de eventos para Android."

};
