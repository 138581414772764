import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { FormattedMessage } from "react-intl";

const Footer = props => {
  const { html, theme } = props;
  const lang = props.langs;
  const links = props.langs.map(({ langKey, link, selected }) => {
    return (
      <li key={langKey}>
        <Link key={langKey} to={link} selected={selected} style={selected? {color:theme.color.neutral.gray.j}: {color:theme.color.neutral.gray.f}}>
          {langKey}
        </Link>
      </li>
    );
  });

  return (
    <React.Fragment>

      <nav>

        <ul><FormattedMessage id="selectLanguage"/>{links}</ul>
      </nav>

      <footer className="footer" dangerouslySetInnerHTML={{ __html: html }}/>

      {/* --- STYLES --- */}
      <style jsx>{`
        .footer {
          background: ${theme.color.neutral.white};
          padding: ${theme.space.inset.default};
          padding-top: 0;
          padding-bottom: 120px;

          :global(ul) {
            list-style: none;
            text-align: center;
            padding: 0;

            :global(li) {
              color: ${theme.color.neutral.gray.g};
              font-size: ${theme.font.size.xxs};
              padding: ${theme.space.xxs} ${theme.space.s};
              position: relative;
              display: inline-block;

              &::after {
                content: "•";
                position: absolute;
                right: ${`calc(${theme.space.xs} * -1)`};
              }
              &:last-child::after {
                content: "";
              }
            }
          }
        }
        nav {
          ul {
            background: ${theme.color.neutral.white};
            padding: ${theme.space.inset.default};
            padding-top: 0;
            padding-bottom: 120px;
            list-style: none;
            text-align: center;
            padding: 0;
            color: ${theme.color.neutral.gray.j};
            font-size: ${theme.font.size.xxs};
            padding: ${theme.space.xxs} ${theme.space.s};
  
            :global(li) {
              padding: ${theme.space.xxs} ${theme.space.s};
              position: relative;
              display: inline-block;
  
              &::after {
                content: "/";
                position: absolute;
                right: ${`calc(${theme.space.xs} * -1)`};
              }
              &:last-child::after {
                content: "";
              }
            }
          }
        }
        

        @from-width desktop {
          .footer {
            padding: 0 1em 1.5em;
          }
        }
      `}</style>
    </React.Fragment>
  );
};

Footer.propTypes = {
  html: PropTypes.string,
  theme: PropTypes.object.isRequired
};

export default Footer;
