module.exports = {
  "siteTitle": "Tintodo - Things to do",
  "shortSiteTitle": "Tintodo  - Things to do",
  "siteDescription": "Discover things to do today. All the events, online events, movies and tv shows from online streaming platforms in one app.",
  "heroHeaderInit": "Tintodo - Things to do",
  "heroSubheaderInit": "Discover things to do today. All the events, online events, movies and tv shows from online streaming platforms in one app.",
  "headerTitle": "Tintodo",
  "headerSubtitle": "Things to do",
  "home": "Home",
  "posts": "Posts",
  "categories": "Categories",
  "about": "About Tintodo",
  "privacyPolicy": "Privacy policy",
  "contact": "Contact",
  "selectLanguage": "Language:",
  "postsCategory": "Posts by category",
  "potsCount1": `There is 1 post in the category`,
  "potsCountPart1": `There are `,
  "potsCountPart2": ` posts by category`,
  "contactPart1": "You can contact us sending an email to ",
  "contactPart2": " or in other platforms like: ",
  "contactPart3": " or ",
  "authorExplanation": " The event's app for Android."
};
